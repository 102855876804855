<template>
  <div class="group-item" :data-is-open="context === showIndex">
    <div class="group-item-title" @click="toggle">
      <span v-if="availabilities && (availabilities.first || availabilities.last)"
      >{{ availabilities.first }} {{ availabilities.last }}</span
      >
      <span v-else class="form-label fs-4 fw-bold mb-3 cursor-pointer">{{
          $t('message.availabilities.availability.title', locale_request)
        }} n° {{ context + 1 }} </span>
    </div>
    <div class="group-item-body" v-show="context === showIndex">
      <div class="row mt-10">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div v-if="availabilities.endDate < getMinDate()">
                  <div class="col-12">
                    <div class="alert alert-dismissible bg-light-danger d-flex flex-column flex-sm-row p-5 mb-10">
                      <div class="d-flex flex-column pe-0 pe-sm-10">
                        <h4 class="fw-semibold">Disponibilité expiré</h4>
                        <span>Cette disponibilité a expiré car la date de fin que vous avez choisie est dépassée. Veuillez sélectionner une nouvelle date de fin pour maintenir votre disponibilité à jour</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 mb-5">
                  <FormulateInput
                      type="date"
                      name="startDate"
                      :label="$t('message.availabilities.availability.forms.startDate.label', locale_request)"
                      help-class="form-text"
                      label-class="form-label fs-6 fw-bold mb-3"
                      input-class="form-control form-control-solid"
                      validation="required"
                      :min="getMinDate()"
                      :validation-messages="{
      required: $t('message.availabilities.availability.required', locale_request)
    }"
                  />
                </div>
                <div class="col-md-6 mb-5">
                  <FormulateInput
                      type="date"
                      name="endDate"
                      :label="$t('message.availabilities.availability.forms.endDate.label', locale_request)"
                      help-class="form-text"
                      label-class="form-label fs-6 fw-bold mb-3"
                      input-class="form-control form-control-solid"
                      validation="required"
                      :min="availabilities.startDate"
                      :validation-messages="{
      required: $t('message.availabilities.availability.required', locale_request)
    }"

                  />
                </div>
                <div class="col-12 mb-5">

                  <FormulateInput
                      type="group"
                      name="journey_top_five_locations"
                      :label="$t('message.availabilities.availability.forms.cities.label', locale_request)"
                      help-class="form-text"
                      label-class="form-label fs-6 fw-bold mb-3"
                      input-class="form-control form-control-solid"
                      :repeatable="true"
                      :add-label="$t('message.availabilities.availability.forms.cities.buttonRepeat', locale_request)"
                      :minimum="1"
                      :limit="5"
                      #default="{ index }"
                      :validation-messages="{
                              required: $t('message.availabilities.availability.required', locale_request)
                            }"
                  >
                    <FormulateInput
                        name="city"
                        ref="cityInput"
                        :label="$t('message.availabilities.availability.forms.cities.city.label', locale_request) + (index + 1)"
                        help-class="form-text"
                        label-class="form-label fs-5 fw-bold mb-3"
                        input-class="form-control form-control-solid"
                        validation="required"
                        :validation-messages="{
                              required: $t('message.availabilities.availability.required', locale_request)
                            }"
                    />
                  </FormulateInput>
                </div>
                <div class="col-12 mb-5">
                  <FormulateInput
                      :label="$t('message.availabilities.availability.forms.journey_isTravelOutsideCities.label', locale_request)"
                      name="journey_isTravelOutsideCities"
                      type="select"
                      :options="{yes: $t('message.dropdown.yes', locale_request), no: $t('message.dropdown.no', locale_request), depend: $t('message.dropdown.depend', locale_request)}"
                      help-class="form-text"
                      label-class="form-label fs-6 fw-bold mb-3"
                      input-class="form-select form-select-solid"
                      validation="required"
                      error-behavior="submit"
                      :validation-messages="{
                              required: $t('message.availabilities.availability.required', locale_request)
                            }"
                  />
                  <FormulateInput
                      type="textarea"
                      v-if="availabilities.journey_isTravelOutsideCities === 'depend'"
                      name="journey_isTravelOutsideCities_depend"
                      :label="$t('message.availabilities.availability.forms.journey_isTravelOutsideCities_depend.label', locale_request)"
                      help-class="form-text"
                      label-class="form-label fs-6 fw-bold mb-3"
                      input-class="form-control form-control-solid"
                      validation="required"
                      error-behavior="submit"
                      :validation-messages="{
                              required: $t('message.availabilities.availability.required', locale_request)
                            }"
                  />

                </div>
                <div class="col-12 mb-5">


                  <div class="card card-flush border">
                    <div class="card-header">
                      <div class="card-title fs-4 fw-bold">
                        {{ $t('message.availabilities.availability.forms.opportunity.mainTitle', locale_request) }}
                      </div>
                    </div>
                    <div class="card-body pt-0">
                      <FormulateInput
                          :label="$t('message.availabilities.availability.forms.opportunity.label', locale_request)"
                          name="contract"
                          placeholder="Sélectionnez un type"
                          type="select"
                          :options="{job: $t('message.dropdown.job', locale_request), freelance: $t('message.dropdown.freelance', locale_request), collab: $t('message.dropdown.collab', locale_request)}"
                          help-class="form-text"
                          label-class="form-label fs-6 fw-bold mb-3"
                          input-class="form-select form-select-solid"
                          validation="required"
                          error-behavior="submit"
                          :validation-messages="{
                              required: $t('message.availabilities.availability.required', locale_request)
                            }"
                      />
                      <div class="row">
                        <div class="col-md-6">
                          <FormulateInput
                              type="select"
                              v-if="availabilities.contract === 'job'"
                              :options="{1: $t('message.dropdown.yes', locale_request), 0: $t('message.dropdown.no', locale_request), 2: $t('message.dropdown.remote', locale_request)}"
                              name="partnershipFullTime_isRemote"
                              :label="$t('message.availabilities.availability.forms.opportunity.options.job.partnershipFullTime_isRemote.label', locale_request)"
                              label-class="form-label fs-6 fw-bold mb-3"
                              input-class="form-control form-control-solid"
                              validation="required"
                              error-behavior="submit"
                              :validation-messages="{
                              required: $t('message.availabilities.availability.required', locale_request)
                            }"
                          />
                        </div>
                        <div class="col-md-6">
                          <FormulateInput
                              type="select"
                              v-if="availabilities.contract === 'job'"
                              :options="{
                              '<50k': '<50k',
                              '50-60k': '50-60k',
                              '60-70k': '60-70k',
                              '70-80k': '70-80k',
                              '80-90k': '80-90k',
                              '90-100k': '90-100k',
                              '100-110k': '100-110k',
                              '110-120k': '110-120k',
                              '120-130k': '120-130k',
                              '130-140k': '130-140k',
                              '140-150k': '140-150k',
                              '150-170k': '150-170k',
                              '170-190k': '170-190k',
                              '190-220k': '190-220k',
                              '220-250k': '220-250k',
                              '>250k': '>250k',

                              }"
                              name="partnershipFullTime_salaryRange"
                              :label="$t('message.availabilities.availability.forms.opportunity.options.job.partnershipFullTime_salaryRange.label', locale_request)"
                              label-class="form-label fs-6 fw-bold mb-3"
                              input-class="form-control form-control-solid"
                              validation="required"
                              error-behavior="submit"
                              :validation-messages="{
                              required: $t('message.availabilities.availability.required', locale_request)
                            }"
                          />
                        </div>
                      </div>


                      <div class="row">
                        <div class="col-md-6">
                          <FormulateInput
                              type="text"
                              v-if="availabilities.contract === 'freelance'"
                              name="freelance_minimumHourlyRate"
                              :label="$t('message.availabilities.availability.forms.opportunity.options.freelance.freelance_minimumHourlyRate.label', locale_request)"
                              help-class="form-text"
                              label-class="form-label fs-6 fw-bold mb-3"
                              input-class="form-control form-control-solid"
                              validation="required|number"
                              error-behavior="submit"
                              :validation-messages="{
                              required: $t('message.availabilities.availability.required', locale_request)
                            }"
                          />
                        </div>
                        <div class="col-md-6">
                          <FormulateInput
                              type="text"
                              v-if="availabilities.contract === 'freelance'"
                              name="freelance_minimumHoursContract"
                              :label="$t('message.availabilities.availability.forms.opportunity.options.freelance.freelance_minimumHoursContract.label', locale_request)"
                              help-class="form-text"
                              label-class="form-label fs-6 fw-bold mb-3"
                              input-class="form-control form-control-solid"
                              validation="required|number"
                              error-behavior="submit"
                              :validation-messages="{
                              required: $t('message.availabilities.availability.required', locale_request)
                            }"
                          />
                        </div>

                      </div>

                      <div class="row">
                        <div class="col-md-12">
                          <FormulateInput
                              type="select"
                              name="collab_free_or_paid"
                              v-if="availabilities.contract === 'collab'"
                              key="collab"
                              :options="{paid: $t('message.dropdown.paid', locale_request), free: $t('message.dropdown.free', locale_request)}"
                              :label="$t('message.availabilities.availability.forms.opportunity.options.collab.collab_free_or_paid.label', locale_request)"
                              label="Quel type de collaboration ?"
                              label-class="form-label fs-6 fw-bold mb-3"
                              input-class="form-control form-control-solid mb-3"
                              validation="required"
                              error-behavior="submit"
                              :validation-messages="{
                              required: $t('message.availabilities.availability.required', locale_request)
                            }"
                          />
                        </div>
                        <div class="col-md-12">
                          <FormulateInput
                              type="select"
                              v-if="availabilities.collab_free_or_paid === 'paid'"
                              name="collab_paid_is_display_price"
                              :options="{yes: $t('message.dropdown.yes', locale_request), no: $t('message.dropdown.no', locale_request)}"
                              :label="$t('message.availabilities.availability.forms.opportunity.options.collab.collab_paid_is_display_price.label', locale_request)"
                              label-class="form-label fs-6 fw-bold mb-3"
                              input-class="form-control form-control-solid mb-3"
                              validation="required"
                              error-behavior="submit"
                              :validation-messages="{
                              required: $t('message.availabilities.availability.required', locale_request)
                            }"
                          />
                        </div>

                        <FormulateInput
                            type="group"
                            name="collab_paid_prices"
                            :repeatable="true"
                            :minimum="1"
                            :label="$t('message.availabilities.availability.forms.opportunity.options.collab.prices.label', locale_request)"
                            :add-label="$t('message.availabilities.availability.forms.opportunity.options.collab.prices.buttonRepeat', locale_request)"
                            validation="required"
                            label-class="form-label fs-6 fw-bold mb-3"
                            v-if="availabilities.collab_paid_is_display_price === 'yes'"
                        >
                          <div class="collab_paid_prices mt-3">
                            <div class="row">
                              <div class="col-md-6">
                                <FormulateInput
                                    type="text"
                                    name="collab_paid_prices_title"
                                    :label="$t('message.availabilities.availability.forms.opportunity.options.collab.prices.collab_paid_prices_title.label', locale_request)"
                                    :placeholder="$t('message.availabilities.availability.forms.opportunity.options.collab.prices.collab_paid_prices_title.placeholder', locale_request)"
                                    label-class="form-label fs-6 fw-bold mb-3"
                                    input-class="form-control form-control-solid mb-3"
                                    validation="required"
                                    error-behavior="submit"
                                    :validation-messages="{
                              required: $t('message.availabilities.availability.required', locale_request)
                            }"
                                />
                              </div>
                              <div class="col-md-6">
                                <FormulateInput
                                    type="text"
                                    name="collab_paid_prices_price"
                                    :label="$t('message.availabilities.availability.forms.opportunity.options.collab.prices.collab_paid_prices_price.label', locale_request)"
                                    :placeholder="$t('message.availabilities.availability.forms.opportunity.options.collab.prices.collab_paid_prices_price.placeholder', locale_request)"
                                    label-class="form-label fs-6 fw-bold mb-3"
                                    input-class="form-control form-control-solid mb-3"
                                    validation="required"
                                    error-behavior="submit"
                                    :validation-messages="{
                              required: $t('message.availabilities.availability.required', locale_request)
                            }"
                                />
                              </div>
                            </div>
                          </div>
                        </FormulateInput>


                      </div>

                    </div>
                  </div>


                </div>
                <div class="col-12 mb-5">
                  <FormulateInput
                      type="text"
                      name="goalArtists"
                      :label="$t('message.availabilities.availability.forms.goalArtists.label', locale_request)"
                      :help="$t('message.availabilities.availability.forms.goalArtists.help', locale_request)"
                      help-class="form-text"
                      label-class="form-label fs-6 fw-bold mb-3"
                      input-class="form-control form-control-solid"
                      validation="required"
                      error-behavior="submit"
                      :validation-messages="{
                              required: $t('message.availabilities.availability.required', locale_request)
                            }"
                  />
                </div>
                <div class="col-md-12 mb-5">
                  <FormulateInput
                      type="textarea"
                      name="introduction"
                      :label="$t('message.availabilities.availability.forms.introduction.label', locale_request)"
                      :help="$t('message.availabilities.availability.forms.introduction.help', locale_request)"
                      validation-name="tweet"
                      error-behavior="live"
                      label-class="form-label fs-6 fw-bold mb-3"
                      input-class="form-control form-control-solid"
                      help-class="form-text"
                  />

                  <div class="form-check form-switch form-check-custom form-check-solid">
                    <FormulateInput
                        type="checkbox"
                        name="is_active"
                        checked
                        :label="$t('message.availabilities.availability.forms.is_active.label', locale_request)"
                        label-class="form-label fs-6 fw-bold mb-0"
                        input-class="form-check-input mt-0 me-4 mb-0"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import {createDateFilter} from "vue-date-fns";
import locale from "date-fns/locale/fr";
import {Loader} from '@googlemaps/js-api-loader';

export default {
  filters: {
    date: createDateFilter("yyyy MMMM dd", {locale})
  },
  props: {
    context: {
      type: Number,
      required: true,
    },

    showIndex: {
      type: Number,
      required: true,
    },
    availabilities: {
      type: [Object, Boolean, Array],
      required: true,
    },
  },
  data() {
    return {
      locale_request: null,
    }
  },
  computed: {
    isEndDateDisabled() {
      return !this.startDate;
    },
    endDateMin() {
      if (this.startDate) {
        const startDate = new Date(this.startDate);
        startDate.setDate(startDate.getDate() + 1);
        const year = startDate.getFullYear();
        const month = String(startDate.getMonth() + 1).padStart(2, '0');
        const day = String(startDate.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      } else {
        return this.getMinDate();
      }
    }
  },
  mounted() {
    this.loadGooglePlaces();
    this.locale_request = $('html').attr('lang');
  },
  methods: {
    handleRepeatableAdded() {
      this.loadGooglePlaces();
    },
    async loadGooglePlaces() {
      const loader = new Loader({
        apiKey: "AIzaSyARh0uWHZeZXgSbMuJvsQ5IH5jK33VCdHY",
        version: "weekly",
        libraries: ["places"],
      });

      await loader.load();

      // L'API Google Places est chargée, vous pouvez maintenant l'utiliser.
      this.initAutocomplete();
    },
    initAutocomplete() {
      const inputs = document.querySelectorAll('.city-input');
      inputs.forEach(input => {
        // Initialisez l'autocomplétion Google Places pour chaque champ de saisie.
        const autocomplete = new google.maps.places.Autocomplete(input);
      });
    },
    getMinDate() {
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, '0');
      const day = String(currentDate.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    },
    isAvailabilityExpired() {
      if (this.endDate) {
        const today = new Date().setHours(0, 0, 0, 0);
        const selectedDate = new Date(this.endDate).setHours(0, 0, 0, 0);
        return selectedDate < today;
      }
      return false;
    },
    toggle() {
      if (this.context === this.showIndex) {
        this.$emit("open", -1); // dont show anything
      } else {
        this.$emit("open", this.context); // show this one
      }
    },
  },
};
</script>

<style scoped>
.group-item-title::after {
  content: "";
  display: inline-block;
  border: 5px solid transparent;
  border-left-color: black;
  margin-left: 0.5em;
  position: relative;
  top: 0;
}

[data-is-open] .group-item-title::after {
  transform: rotate(90deg);
  top: 2px;
}
</style>